.card{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important; 
  width: 60%;
}
.result-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.searchbox-container{
  border: 1px solid rgb(211, 211, 211);
  border-radius: 15px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 16px 6px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.searchbox{
  border: none;
  outline : none;
  width: 100%;
}
.fa-magnifying-glass{
  object-fit: cover;
  font-size: 23px;
  color: rgb(198, 193, 193);
}
table, td, th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.chip{
  cursor: pointer;

}

.react-color{
  color: #09d3ac !important;
}

.angular-color{
  color: #c3002f !important;
}
.html-color{
  color:Tomato
}
.css-color{
  color:#264DE4
}
.js-color{
  color:#ffe95c; 
}

.chip-active {
  background-color: black;
  color: white;
}


@media (min-width: 300px) and (max-width: 768px){
  .card{
    width: 90% !important;
  }
  
  .card-body{
    overflow: scroll;
  }
}